import http from "./axios";
import apiUrl from "./api";
// import qs from 'qs';
import { Qs, uuid } from "../common/js/tools";
const params = Qs(); // 获取params
// 确认用款页详情
export const loanDetailConfirmService = () => {
    return http.post(apiUrl.loanDetailConfirm, {
        conceive: params.conceive, // 产品id
    }, {
        conceive: params.conceive, // 产品id
        devoted: uuid(), // 混淆字段
    });
}
// 确认用款
export const loanDetailPushService = (data) => {
    let { amount, periods, termUnit } = data;
    return http.post(apiUrl.loanDetailPush, {}, {
        grunts: amount, // 金额
        epsilon: periods, // 期限
        sigmaf: termUnit, // 期限类型
        conceive: params.conceive, // 产品id
        neoptolemus: uuid(), // 混淆字段
    });
}
// 订单详情页
export const loanDetailsService = () => {
    return http.get(apiUrl.loanDetails, {
        notable: params.notable, // 订单id
        conceive: params.conceive, // 产品id
    }, {
        conceive: params.conceive, // 产品id
        notable: params.notable, // 订单id
        moral: uuid(), // 混淆字段
    });
}
// google评分
export const googleScoreService = (data) => {
    let { clickIndex } = data
    return http.post(apiUrl.googleScore, {}, {
        definite: clickIndex, // 分数 1-5
        hundred: uuid(), // 混淆字段
    });
}
// 原卡重试确认订单
export const userReloanService = (data) => {
    let { orderNo } = data;
    return http.post(apiUrl.userReloan, {}, {
        vexed: orderNo, // 订单号
    });
}
// 获取还款计划信息
export const loanPlayDetailService = () => {
    return http.post(apiUrl.loanPlayDetail, {}, {
        notable: params.notable, // 订单id
        conceive: params.conceive, // 产品id
    });
}
// 获取还款方式
export const getLoanPaymentService = () => {
    return http.post(apiUrl.getLoanPayment, {
        notable: params.notable, // 订单id
        conceive: params.conceive, // 产品id
    }, {
        notable: params.notable, // 订单id
        conceive: params.conceive, // 产品id
    });
}
// 展期详情页
export const delayDetailService = () => {
    return http.get(apiUrl.delayDetail, {
        notable: params.notable, // 订单id
        conceive: params.conceive, // 产品id
    }, {
        notable: params.notable, // 订单id
        conceive: params.conceive, // 产品id
    });
}
// 获取绑卡信息（第五项）
export const cardInitService = () => {
    return http.get(apiUrl.cardInit, {
        resolutely: 1, // 是否添加新卡（app认证项给0，h5添加新卡给1）
    }, {
        resolutely: 1, // 是否添加新卡（app认证项给0，h5添加新卡给1）
        dicky: uuid(), // 混淆字段
    });
}
// 提交绑卡（第五项）
export const bindCardNewService = (data) => {
    return http.post(apiUrl.bindCardNew, {}, data);
}
// 用户银行卡列表
export const userCardListService = () => {
    return http.post(apiUrl.userCardList, {}, {});
}
// 更换银行卡（会触发重新打款）
export const changeOrderAccountService = (data) => {
    let { bindId } = data;
    return http.post(apiUrl.changeOrderAccount, {}, {
        squatting: bindId || "", // 绑卡id
        unlimited: params.notable || "", // 订单号 （订单列表、订单详情等页面需要给，个人中心这种拿不到的不用给）
    });
}
// 获取客服页面数据
export const contactKfInfoService = () => {
    return http.post(apiUrl.contactKfInfo, {}, {
        manufactory: uuid(), // 混淆字段
    });
}
// 获取还款code码
export const generateVcodeService = (from, data) => {
    return http.post(apiUrl.generateVcode, {
        notable: params.notable, // 订单id
        trapesing: params.conceive, // 产品id
    }, {
        notable: params.notable, // 订单id
        trapesing: params.conceive, // 产品id
        grunts: from == 'card' ?  data.amount : params.amount, // 金额
        quarter: from == 'card' ? data.isDelay : params.isDelay, // 是否展期 1是 0否 暂无展期默认先写0吧
        thong: from == 'card' ? parseInt(data.cardType) : parseInt(params.cardType), // 还款方式 1 钱包 2 银行 3 线下门店
        pachyderms: from == 'card' ? data.typeCode : params.typeCode, // 申请code代码
        eared: uuid(), // 混淆字段
    });
}
// 禁止产品详情 - 小黑屋
/*export const prohibitedProductService = () => {
    return http.post(apiUrl.prohibitedProduct, {
        notable: params.notable, // 订单id
        conceive: params.conceive, // 产品id
    }, {
        beats: params.conceive, // 产品id
        impudent: uuid(), // 混淆字段
        cousinship: uuid(), // 混淆字段
    })
}*/
// 确认用款页假产品数据变更接口
export const planListService = (data) => {
    return http.post(apiUrl.planList, {
        conceive: params.conceive, // 产品id
    }, {
        grunts: data.amount, // 金额
        epsilon: data.periods, // 期限
        sigmaf: data.termUnit, // 期数
        conceive: params.conceive, // 产品id
        virtue: "", // 几折 例如9折传9
        recalcitrant: 2, // 1等额本金2等额本息
        devoted: uuid(), // 混淆字段
    })
}
import React from "react";
import './index.less';
import confirm_goBank from '../../common/images/confirmOfLoan/confirm_goBank.png';

const Details = (props) => {
    const { title, details, isColumn, children } = props;
    return (
        <div className="M-details">
            <div className="details_title">
                <p>{ title }</p>
                <img src={confirm_goBank} className="next" />
            </div>
            {details && details.length > 0 ? (
                <ul className="details_info">
                    {details.map((item ,index) => {
                        return (
                            <li key={"details" + index} className={isColumn ? "column_info" : "row_info"}>
                                <p className={isColumn ? "desc" : "title"}>{ item.installation }</p>
                                <p className={isColumn ? "title" : "desc"}>{ item.unless }</p>
                            </li>
                        )
                    })}
                </ul>
            ) : <div className="other_info">
                   { children } 
                </div>}
        </div>
    )
}

export default Details;
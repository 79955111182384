import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import qs from 'qs';
import { setTitle } from "../../../common/js/tools";
import { setNavColor, setNavExpansion } from '../../../common/js/navigateTools';
import { cardInitService, bindCardNewService } from "../../../axios/service";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import "./index.less";
import chooseArrow from '../../../common/images/confirmOfLoan/confirm_goBank.png';
import inputArrow from "../../../common/images/bindCard/inputArrow.png";
import card_checked from "../../../common/images/repay/card_checked.png";

const BindCard = (props) => {
    // 路由params
    const location = useLocation();
    const params = qs.parse(location.search.substring(1, location.search.length));

    const cardTypeList = [{
        type: "E-wallet",
        cardId: 1,
    }, {
        type: "Bank",
        cardId: 2,
    }];

    const [eWalletList, setEwalletList] = useState([]); // 电子钱包详情
    const [bankList, setBankList] = useState([]); // 银行卡详情
    const [choosedCardTypeList, setChoosedCardTypeList] = useState([]); // 需要选择的银行卡/e-wallet的类别的列表

    const [bankCardItemList, setBankCardItemList] = useState([]); // bankCardList
    const [ewalletCardItemList, setEwalletCardItemList] = useState([]); // 电子钱包的列表

    const [isShowCardTypeModal, setIsShowCardTypeModal] = useState(false);// 是否显示card卡类型的弹框
    const [choosedCardType, setChoosedCardType] = useState(params.choosedCardType || ""); // 选择的卡的类型 - 默认params携带过来的选择的卡的类型 可以通过Select进行切换
    
    const [isShowCardItemModal, setIsShowCardItemModal] = useState(false); // 是否显示选择卡的item的弹框 选择要添加的卡的类别
    const [bindCardItemList, setBindCardItemList] = useState([]); // 银行卡的列表
    const [bindCardItemName, setBindCardItemName] = useState(""); // 要选择的卡的key
    const [bankCardItemName, setBankCardItemName] = useState(""); // 银行卡的key
    const [ewalletCardItemName, setEwalletCardItemName] = useState(""); // 电子钱包的key
    const [choosedBindCardItem, setChoosedBindCardItem] = useState({}); // 被选中的卡类别的数据

    const [codeDic, setCodeDic] = useState({}); // 存放输入的绑卡的数据

    useEffect(() => {
        setTitle("");
        setNavExpansion("0"); // 隐藏导航栏
        setNavColor("", "#fff");

        const getBaseInfo = async () => {
            let data = await cardInitService();
            var curr_ewalletList = [], curr_bankList = [];
            var curr_ewalletCardItemList = [], curr_bankCardItemList = [];
            var curr_ewalletCardItemName = "", curr_bankCardItemName = "";
            data.visiting && data.visiting.map(item => {
                if(item.acerbities == 1) { // E-wallet
                    curr_ewalletList = item.visiting;
                    setEwalletList(curr_ewalletList);
                    item.visiting.map(child => {
                        if(child.indefatigable == "wqq2") { // wqq1选择框 wqq2输入内容
                            let ecodeDic = codeDic; // 需要存入的key-value对象
                            ecodeDic[child.forgets] = child.kindest || ""; // 初始化时key[对应的若有默认value信息则存入默认value信息
                            setCodeDic(ecodeDic);
                        } else if(child.indefatigable == "wqq1") { // wqq1选择框 wqq2输入内容
                            curr_ewalletCardItemList = child.unfavourable || [];
                            setEwalletCardItemList(curr_ewalletCardItemList); // E-wallet的选择框的数组内容
                            curr_ewalletCardItemName = child.forgets || "";
                            setEwalletCardItemName(curr_ewalletCardItemName); // E-wallet的选择框的key
                        }
                    })
                } else if(item.acerbities == 2) { // Bank
                    curr_bankList = item.visiting;
                    setBankList(curr_bankList);
                    item.visiting.map(child => {
                        if(child.indefatigable == "wqq2") { // wqq1选择框 wqq2输入内容
                            let bcodeDic = codeDic; // 需要存入的key-value对象
                            bcodeDic[child.forgets] = child.kindest || ""; // 初始化时key[对应的若有默认value信息则存入默认value信息]
                            setCodeDic(bcodeDic);
                        } else if(child.indefatigable == "wqq1") { // wqq1选择框 wqq2输入内容
                            curr_bankCardItemList = child.unfavourable || [];
                            setBankCardItemList(curr_bankCardItemList); // 银行卡的选择框的数组内容
                            curr_bankCardItemName = child.forgets || "";
                            setBankCardItemName(curr_bankCardItemName); // 银行卡的选择框的key
                        }
                    })
                }
                let curr_choosedCardTypeList = choosedCardType && choosedCardType == 1 ? curr_ewalletList : curr_bankList; // 绑卡信息列表 Bank/E-wallet
                setChoosedCardTypeList(curr_choosedCardTypeList);
                let curr_bindCardItemList = choosedCardType && choosedCardType == 1 ? curr_ewalletCardItemList : curr_bankCardItemList; // 要选择的选择框的卡的列表
                setBindCardItemList(curr_bindCardItemList);
                let curr_bindCardItemName = choosedCardType && choosedCardType == 1 ? curr_ewalletCardItemName : curr_bankCardItemName; // 要选择的卡的选择框的key
                setBindCardItemName(curr_bindCardItemName);
            })
        }
        getBaseInfo();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])

    // 添加新的银行卡/ewallet
    const addNewBank = async () => {
        let paramDic = {
            fitted: choosedCardType, // 1.ewallet 2 bank
            [bindCardItemName]: choosedBindCardItem.acerbities, // 选中的卡的id
            suddenly: "FGSADA42643432" //混淆字段
        }
        Object.keys(codeDic).map((key, index) => {
          if(key !== bindCardItemName) {
            paramDic[key] = codeDic[key];
          }
        });
        if (choosedCardType == 1) {
            eWalletList.map((item) => {
                if (item.indefatigable == 'wqq2') { // 输入框
                    if (!Object.keys(codeDic).includes(item.forgets)) {
                        paramDic[item.forgets] = item.kindest; // 没有输入的话采用kindest - displayValue
                    }
                }
            })
        }
        console.log(codeDic ,paramDic);
        // 确认
        let data = await bindCardNewService(paramDic);
        // console.log(data);
        window.history.back();
    }
    // 修改当前选择的卡类型 - 切换对应的类型的列表
    const changeChoosedCardTypeList = (item) => {
        let now_choosedCardType = item.cardId;
        setChoosedCardType(now_choosedCardType);
        let now_choosedCardTypeList = item.cardId && item.cardId == 1 ? eWalletList : bankList;
        setChoosedCardTypeList(now_choosedCardTypeList);
        let now_bindCardItemList = item.cardId == 1 ? ewalletCardItemList : bankCardItemList;
        setBindCardItemList(now_bindCardItemList);
        let now_bindCardItemName = item.cardId == 1 ? ewalletCardItemName : bankCardItemName;
        setBindCardItemName(now_bindCardItemName);


        setIsShowCardTypeModal(false);
        setCodeDic({}); // 将传入的key清除
        setChoosedBindCardItem({}); // 将选中的银行卡清除

        now_choosedCardTypeList.map(child => {
            if(child.indefatigable == "wqq2") { // wqq1选择框 wqq2输入内容
                let ccodeDic = codeDic; // 需要存入的key-value对象
                ccodeDic[child.forgets] = child.kindest || ""; // 初始化时key[对应的若有默认value信息则存入默认value信息
                ccodeDic[now_bindCardItemName] = ""; // 清空选择的卡名
                setCodeDic(ccodeDic);
            }
        })
    }
    // 选择要添加的银行卡/e-wallet
    const changeChoosedBindCardItem = (item) => {
        let codeDicI = codeDic;
        codeDicI[bindCardItemName] = item.faults;
        setChoosedBindCardItem(item); // 被选中的卡类别的数据
        setIsShowCardItemModal(false); // 是否显示选择卡的item的弹框 选择要添加的卡的类别
        setCodeDic(codeDicI); // 将卡名传给key
    }
    // 获取当前input中的内容 将内容推入到对应的key中
    const inputChange = (code, event) => {
        let codeDicN = codeDic;
        codeDicN[code] = event.target.value;
        setCodeDic(codeDicN);
    }
    // input显示的value
    const valueBind = (code) => {
        return Object.keys(codeDic).includes(code) ? codeDic[code] : ""; // 返回对应的key的内容 value显示
    }
    // 展示弹出选择银行卡弹框
    const showItemModal = () => {
        setIsShowCardItemModal(true);
    }
    // 隐藏选择银行卡弹框
    const closeItemModal = () => {
        setIsShowCardItemModal(false);
    }

    return (
        <div className="P-bindCard">
            {/* 绑定新卡信息 */}
            <div className="P-bindCard_inputArea">
                {/* 类型选择 */}
                <div className="inputArea_typeList">
                    {cardTypeList.map((item, index) => {
                        return (
                            <div className="type" onClick={() => changeChoosedCardTypeList(item)} key={"cardType" + index}>
                                <p className={choosedCardType == item.cardId ? "chosedType" : ""}>{ item.type || "" }</p>
                            </div>
                        )
                    })}
                </div>
                {/* input输入列表 */}
                <div className='inputArea_inputList'>
                    {choosedCardTypeList.length > 0 && choosedCardTypeList.map((item, index) => {
                        return (
                            <div className='inputList' key={"cardInputList" + index}>
                                <span className='inputList_title'>{item.interfering || ""}</span>
                                <div className="inputList_input" style={item.indefatigable == "wqq1" ? {border: "3px solid #007CFB", borderRadius: "2px"} : {borderBottom: "3px solid #007CFB"}}>
                                    <div className={item.indefatigable == "wqq1" && Object.keys(choosedBindCardItem).length !== 0 ? 'inputList_input_inputchoosedbg' : 'inputList_input_con'}>
                                        {item.indefatigable == "wqq1" && choosedCardType == 1 &&
                                            choosedBindCardItem && choosedBindCardItem.favour ? 
                                            (<img src={choosedBindCardItem.favour} className='inputList_input_itemImage' />) : 
                                            (item.indefatigable == "wqq1" && choosedCardType == 1 && <span className='inputList_input_itemImage_none'></span>)
                                        }
                                        <input
                                            placeholder={item.livers}
                                            // disabled={item.indefatigable == "wqq1" ? true : false} // ee选择框 rr输入框
                                            readOnly={item.indefatigable == "wqq1" ? true : false} // ee选择框 rr输入框
                                            type={item.commanding == 1 ? "number" : "text"}
                                            pattern={item.commanding == 1 ? "[0-9]*" : ""} // 1数字
                                            inputMode={item.commanding == 1 ? "numeric" : "text"} // 是否唤起数字键盘/文字键盘
                                            onChange={event => inputChange(item.forgets, event)}
                                            defaultValue={valueBind(item.forgets)}
                                            className={item.indefatigable == "wqq1" && choosedCardType == 1 ? "input_choose_area" : ""}
                                            id='cardInput'
                                            key={item.forgets + "-i" + choosedCardType}
                                        />
                                        {item.indefatigable == "wqq1" && <div className='input_click' onClick={() => showItemModal()}></div>}
                                    </div>
                                    <img
                                    onClick={() => item.indefatigable == "wqq1" && showItemModal()} 
                                    src={item.indefatigable == "wqq1" ? chooseArrow : inputArrow}
                                    className='inputList_input_selectIcon' />
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/* 按钮提交 */}
                <div className="inputArea_button">
                    <Button
                    content="Confirm"
                    clickFunc={() => addNewBank()} />
                </div>
            </div>
            {/* 选择要添加的卡 的 弹框 */}
            {isShowCardItemModal ? (
                <Modal
                titleContent={choosedCardType == 1 ? "E-wallet" : "Bank"}
                close={() => closeItemModal()}>
                    <ul className='cardModal'>
                        {bindCardItemList && bindCardItemList.map((item, index) => {
                          return (
                            <li 
                            key={"bindCardItem" + index} 
                            className='cardModal_cardItemList'
                            onClick={() => changeChoosedBindCardItem(item)}
                            >
                              <div className='item_info'>
                                {item.favour && (<img src={item.favour || ""} className='item_icon' />)}
                                <div className={choosedBindCardItem && choosedBindCardItem.acerbities == item.acerbities ? "choosedItemColor" : "normalItemColor"}>
                                    <p>{item.faults || ""}</p>
                                </div>
                              </div>
                              {choosedBindCardItem && choosedBindCardItem.acerbities == item.acerbities ? <img src={card_checked} className='item_choosed' /> : null}
                            </li>
                          )
                        })}
                    </ul>
                </Modal>
            ) : null}
        </div>
    )
}

export default BindCard;
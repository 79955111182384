import React, { useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import qs from 'qs';
import { setTitle } from "../../common/js/tools";
import { ToastType } from "../../common/js/toastList";
import { loanDetailsService, googleScoreService, userReloanService } from "../../axios/service";
import { setNavExpansion, openUrl, toGrade, jumpToHome, closeSyn, jumpToEmailNative, setNavColor } from '../../common/js/navigateTools';
import Button from "../../components/button";
import ErrorModal from "../../components/errorModal";
import DetailsTop from "../../components/detailsTop";
import Modal from "../../components/modal";
import Details from "../../components/details";
import "./loanDetails.less";
import scoreStarNotChoosed_01 from "../../common/images/status/scoreStarNotChoosed_01.png";
import scoreStarNotChoosed_02 from "../../common/images/status/scoreStarNotChoosed_02.png";
import scoreStarNotChoosed_03 from "../../common/images/status/scoreStarNotChoosed_03.png";
import scoreStarNotChoosed_04 from "../../common/images/status/scoreStarNotChoosed_04.png";
import scoreStarNotChoosed_05 from "../../common/images/status/scoreStarNotChoosed_05.png";
import scoreStarChoosed_01 from "../../common/images/status/scoreStarChoosed_01.png";
import scoreStarChoosed_02 from "../../common/images/status/scoreStarChoosed_02.png";
import scoreStarChoosed_03 from "../../common/images/status/scoreStarChoosed_03.png";
import scoreStarChoosed_04 from "../../common/images/status/scoreStarChoosed_04.png";
import scoreStarChoosed_05 from "../../common/images/status/scoreStarChoosed_05.png";
import closeIcon from "../../common/images/closeIcon.png";

import status21 from '../../common/images/status/status21.png';
import status110 from '../../common/images/status/status110.png';
import status151 from '../../common/images/status/status151.png';
import status169 from '../../common/images/status/status169.png';
import status174 from '../../common/images/status/status174.png';
import status180 from '../../common/images/status/status180.png';
import ingList from '../../common/images/status/ingList.png';
import confirm_goBank from '../../common/images/confirmOfLoan/confirm_goBank.png';
import bankError_confirm_errorIcon from '../../common/images/bankError/bankError_confirm_errorIcon.png';


const LoanDetails = (props) => {
    // 路由params
    const location = useLocation();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `declamation=${params.declamation}`;

    const [orderStatus, setOrderStatus] = useState(null); // 订单状态  110"额度审核失败" ,151"放款中",170"放款成功(待还款),174"待还款,169"放款失败",175"还款中",179"还款失败",200"贷款结清通知",180"逾期通知",21 审核中，110审核不通过，22待绑卡，120待提现，10等待补充信息认证
    const [noticeStatusText, setNoticeStatusText] = useState(""); // status txt
    const [noticeDesText, setNoticeDesText] = useState(""); // status desc
    const [productName, setProductName] = useState(""); // 产品name
    const [productLogo, setProductLogo] = useState(""); // 产品logo
    const [detail, setDetail] = useState([]); // 用户信息数据列表
    const [goLoanUrl, setGoLoanUrl] = useState(""); // 再来一单url
    const [orderNo, setOrderNo] = useState(""); // 订单order_no
    const [loanFailed, setLoanFailed] = useState({}); // 付款失败按钮显示内容 == 169
    const [scoreList, setScoreList] = useState([{
        not_checked: scoreStarNotChoosed_01,
        checked: scoreStarChoosed_01,
        index: 1,
    }, {
        not_checked: scoreStarNotChoosed_02,
        checked: scoreStarChoosed_02,
        index: 2,
    }, {
        not_checked: scoreStarNotChoosed_03,
        checked: scoreStarChoosed_03,
        index: 3,
    }, {
        not_checked: scoreStarNotChoosed_04,
        checked: scoreStarChoosed_04,
        index: 4,
    }, {
        not_checked: scoreStarNotChoosed_05,
        checked: scoreStarChoosed_05,
        index: 5,
    }]); // 评分列表
    const [isScore, setIsScore] = useState(0); // 是否显示评分弹框
    const [clickIndex, setClickIndex] = useState(0); // 评分分数
    const [isDelay, setIsDelay] = useState(0); // 是否展示展期按钮

    const [autoAmount, setAutoAmount] = useState(null); // 上方显示的金额
    const [autoAmountTxt, setAutoAmountTxt] = useState(""); // 上方显示的金额提示
    const [bankCard, setBankCard] = useState(""); // 银行账户号
    const [bankName, setBankName] = useState(""); // 银行名称
    const [bankText, setBankText] = useState(""); // 银行卡文案

    const [isSms, setIsSms] = useState(false); // 是否发送短信
    const [isShowDetails, SetIsShowDetails] = useState(false); // 是否显示详情 - 审核时
    const statusList = [{
        status: 110,
        statusImg: status110
    }, {
        status: 151,
        statusImg: status151
    }, {
        status: 174,
        statusImg: status174
    }, {
        status: 180,
        statusImg: status180
    }];
    const howList = [{
        installation: "1. Make Timely Payments",
        unless: "Ensure that all bills, including credit card payments, loans, and utility bills, are paid on time.",
    }, {
        installation: "2. Maintain a Stable Employment Record",
        unless: "A stable job history can make you appear more reliable to lenders."
    }, {
        installation: "3. Provide Accurate Income Proof",
        unless: "Higher income can improve your financial situation and make you eligible for better rates."
    }]

    // 放款失败 银行报错弹框
    const [isBoxBank, setIsBoxBank] = useState(0); // 是否弹框 1弹 0不弹
    const [loanFailedTxt, setLoanFailedTxt] = useState(""); // 弹框里的文字
    const [loanFailedTitle, setLoanFailedTitle] = useState(""); // 弹框里的标题
    const [loanFailedType, setLoanFailedType] = useState(0); // 0 正常银行卡，1和7限额银行卡，2是错误账户银行卡 99未知错误银行卡

    // 待还款，逾期状态未更新
    const [isBoxStatusNoUpdate, setIsBoxStatusNoUpdate] = useState(0); // 是否弹框 1弹 0不弹
    const [statusNoUpdateTxt, setStatusNoUpdateTxt] = useState(""); // 弹框里的文字
    const [statusNoUpdateTitle, setStatusNoUpdateTitle] = useState(""); // 弹框里的标题
    const [emailTitle, setEmailTitle] = useState(""); // 邮箱的标题
    const [email, setEmail] = useState(""); // 邮箱地址

    const [isStatusCheck, setIsStatusCheck] = useState(true); // 展示查询状态弹框

    const [isDisplayShelf, setIsDisplayShelf] = useState(0); // 1假产品0真产品
    useEffect(() => {
        setTitle("");
        setNavExpansion("0"); // 隐藏导航栏

        // 获取订单详情
        const getLoanData = async () => {
            let data = await loanDetailsService();
            // console.log(data);
            setOrderStatus(data.absolutely || ""); // data.absolutely || ""
            setNoticeStatusText(data.shallow || "");
            setNoticeDesText(data.selfish || "");
            setProductName(data.proportion || "");
            setProductLogo(data.occupying || "");
            setDetail(data.adopted && data.adopted.dressed || []);
            setOrderNo(data.unlimited || "");
            setLoanFailed(data.beauties || {});
            setGoLoanUrl(data.problematic || "");
            setIsScore(data.mostly || 0);
            setIsDelay(data.quarter || 0);

            setAutoAmount(data.sincerity || 0);
            setAutoAmountTxt(data.floor || "");
            setBankCard(data.replenished || "");
            setBankName(data.attempting || "");
            setBankText(data.insight || "");

            // 放款失败 银行报错弹框
            setIsBoxBank(data.omicron || 0); // 是否弹框 1弹 0不弹 
            setLoanFailedTxt(data.oldest || ""); // 弹框里的文字
            setLoanFailedTitle(data.gamma || ""); // 弹框里的标题
            setLoanFailedType(data.unusual || 0); // 0 正常银行卡，1和7限额银行卡，2是错误账户银行卡 99未知错误银行卡 

            // 待还款，逾期状态未更新
            setIsBoxStatusNoUpdate(data.solid || 0); // 是否弹框 1弹 0不弹 
            setStatusNoUpdateTxt(data.conclusions || "") // 弹框里的文字
            setStatusNoUpdateTitle(data.comprehensive || "") // 弹框里的标题
            setEmailTitle(data.therefrom || ""); // 邮箱的标题
            setEmail(data.ermine || ""); // 邮箱地址

            setIsDisplayShelf(data.reception); // 1假产品0真产品
            if(data.absolutely == 200) {
                setNavColor('', '#F5F5F5');
            } else {
                setNavColor('', '#000');
            }
        }
        getLoanData();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])

    // 去还款页 - 单期
    const goPayPeriod = () => {
        // navigate(`/movedSaints?notable=${params.notable}&conceive=${params.conceive}&${paramsDetail}`);
        window.location.href = `/movedSaints?notable=${params.notable}&conceive=${params.conceive}&${paramsDetail}`;
    }
    // 去还款页 - 展期
    const goPayDelay = () => {
        // navigate(`/reallySayre?notable=${params.notable}&conceive=${params.conceive}&${paramsDetail}`);
        window.location.href = `/reallySayre?notable=${params.notable}&conceive=${params.conceive}&${paramsDetail}`;
    }
    // 跳转换绑卡列表（更换账户）
    const goBindCardList = () => {
        // 跳转 换绑卡（更换账户）
        if (loanFailed.preoccupied.blame != undefined && loanFailed.preoccupied.blame.length > 0) {
            // navigate(`${loanFailed.preoccupied.blame}&${paramsDetail}`);
            window.location.href = `${loanFailed.preoccupied.blame}&${paramsDetail}`;
        }else {
            // navigate(`/mirrorWould?notable=${orderNo}&conceive=${params.conceive}&${paramsDetail}`);
            window.location.href = `/mirrorWould?notable=${orderNo}&conceive=${params.conceive}&${paramsDetail}`;
        }
    }
    // 原卡重试
    const reTryOriginCard = async () => {
        let data = await userReloanService({
            orderNo: orderNo,
        });
        // console.log(data);
        // navigate(`${data.assist}&${paramsDetail}`); // 跳转订单详情页
        window.location.href = `${data.assist}&${paramsDetail}`;
    }
    // 再来一单
    const goToLoan = () => {
        // 跳转原生页面
        let url_type = goLoanUrl.slice(0, 4);
        if(url_type == "http") {
            window.location.href = `${goLoanUrl}&${paramsDetail}`;
        } else if(url_type == "cll:") {
            openUrl(`${goLoanUrl}`); // ?relations=params.conceive
        }
    }
    // 回到首页
    const goHome = () => {
        jumpToHome();
    }
    // 关闭评分弹框
    const closeScoreModal = () => {
        setIsScore(0);
    }
    // 点击评分
    const clickScore = (item) => {
        // let the_scoreList = scoreList;
        // the_scoreList.map((child, i) => {
        //     if(i == index) {
        //         return the_scoreList[i] = scoreStarChoosed;
        //     } else {
        //         return the_scoreList[i] = scoreStarNotChoosed;
        //     }
        // });
        // setScoreList(the_scoreList);
        setClickIndex(item.index);
    }
    // 提交评分
    const upScore = async () => {
        if(clickIndex == 0) {
            ToastType('Toast', 'Please select');
            return;
        }
        let data = await googleScoreService({
            clickIndex: clickIndex,
        });
        toGrade();
        setIsScore(0);
        setClickIndex(0);
    }
    // 跳转换绑卡
    const changeAccount = () => {
        // navigate(`/mirrorWould?notable=${orderNo}&conceive=${params.conceive}&${paramsDetail}`);
        window.location.href = `/mirrorWould?notable=${orderNo}&conceive=${params.conceive}&${paramsDetail}`;
    }
    // 关闭 放款失败银行错误弹框
    const closeBankModal = () => {
        closeSyn(); // 关闭当前H5
    }
    // 关闭 还款状态未更新弹框
    const closeStatusModal = () => {
        setIsBoxStatusNoUpdate(0);
    }
    // 跳转带标题和订单号的邮箱 js交互
    const jumpToEmailNativigate = () => {
        jumpToEmailNative(email, emailTitle, orderNo);
    }

    return (
        <div className="P-loanDetails">
            {/* 信息展示区域 */}
            {orderStatus == 21 || Object.keys(loanFailed).length > 0 && loanFailed.angling == 1 ? (
                <div className="P-loanDetails_other">
                    <div className="other_area">
                        <DetailsTop 
                        title={autoAmountTxt}
                        tipsContent={noticeDesText || ""}>
                            <div className="status_amount">
                                {orderStatus == 21 ? <img src={status21} className="statusImg" /> : null}
                                {Object.keys(loanFailed).length > 0 && loanFailed.angling == 1 ? <img src={status169} className="statusImg" /> : null}
                                <p>{ autoAmount || "0"}</p>
                            </div>
                        </DetailsTop>
                        {orderStatus == 21 ? (
                            <div className="status21">
                                <div className="status21_sms">
                                    <p>Payment Reminder SMS</p>
                                    <div className={isSms ? "sms_scroll issms" : "sms_scroll"} onClick={() => setIsSms(!isSms)}>
                                        <div className="scorll_div"></div>
                                    </div>
                                </div>
                                <img src={ingList} />
                            </div>
                        ) : null}
                        {Object.keys(loanFailed).length > 0 && loanFailed.angling == 1 ? (
                            <div className="status169">
                                <p>{bankName || "DBO Bank Bank"}</p>
                                <div className="card">
                                    {loanFailedType != 0 ? <img src={bankError_confirm_errorIcon} /> : null}
                                    <p className="bankcard">{bankCard || "9999 9999 9999 9999"}</p>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    {orderStatus == 21 ? (
                        <div className="status21_details" onClick={() => SetIsShowDetails(true)}>
                            <p>Loan Details</p>
                            <img src={confirm_goBank} />
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className="P-loanDetails_info" style={{background: orderStatus == 200 ? '#F5F5F5' : '#2C2C36'}}>
                    <div className="infoArea">
                        <DetailsTop 
                        title={autoAmountTxt}
                        tipsContent={noticeDesText || ""}>
                            <div className="status_amount">
                                {statusList.map((item, index) => {
                                    return (
                                        item.status == orderStatus ? <img src={item.statusImg} className="statusImg" key={"statusImg" + index} /> : null
                                    )
                                })}
                                <p style={orderStatus == 200 ? {color: '#007CFB', fontSize: '50px'} : {}}>{ autoAmount || "0"}</p>
                            </div>
                        </DetailsTop>
                    </div>
                    <div className="detailsArea">
                        <Details 
                        title={orderStatus == 110 ? "How to Increase Approval Rate" : "Loan Details"}
                        details={orderStatus == 110 ? howList : detail}
                        isColumn={orderStatus == 110 ? true : false} />
                    </div>
                    {orderStatus == 110 ? (
                        <div className="status21_details" onClick={() => SetIsShowDetails(true)}>
                            <p>Loan Details</p>
                            <img src={confirm_goBank} />
                        </div>
                    ) : null}
                </div>
            )}
            {/* 按钮 */}
            {orderStatus == 174 || orderStatus == 180 ? (
                <div className="P-loanDetails_button">
                    {/* 延期还款 */}
                    {isDelay ? 
                        <div className="buttonArea" style={{marginRight: '20px'}}>
                            <Button
                            content= "Extension"
                            clickFunc= {() => goPayDelay()}
                            btnStyle= {{background: '#fff', color: '#2C2C36'}} />
                        </div> 
                    : null}
                    {/* 正常还款 */}
                    <div className="buttonArea">
                        <Button
                        content= "Repay"
                        clickFunc= {() => goPayPeriod()} />
                    </div>
                </div>
            ) : null}
            {loanFailed && Object.keys(loanFailed).length > 0 && loanFailed.angling == 1 ? (
                <div className="P-loanDetails_button failedBtn">
                {/* 原卡重试 */}
                {loanFailed.abundance && loanFailed.abundance.convictions == 1 ? 
                    <div className="buttonArea">
                        <Button
                        content= {loanFailed.abundance.rumours}
                        clickFunc= {() => reTryOriginCard()}
                        btnStyle= {{background: '#fff', color: '#2C2C36'}} />
                    </div> 
                : null}
                {/* 更换账户 */}
                {loanFailed.preoccupied && loanFailed.preoccupied.convictions == 1 ?
                    <div className="buttonArea">
                        <Button
                        content= {loanFailed.preoccupied.rumours}
                        clickFunc= {() => goBindCardList()} />
                    </div>
                :null}
            </div>
            ) : null}
            {/* 按钮 */}
            {orderStatus == 110 || (orderStatus == 200 && goLoanUrl.length > 0) ? <div className="P-loanDetails_button" style={{background: orderStatus == 200 ? '#F5F5F5' : '#2C2C36'}}>
                {/* 审核失败 - 返回首页 */}
                {orderStatus == 110 && (
                    <div className="buttonArea">
                        <Button
                        content= "Home"
                        clickFunc= {() => goHome()} />
                    </div>
                )}
                {/* 再来一单 */}
                {orderStatus == 200 && goLoanUrl.length > 0 && (
                    <div className="buttonArea">
                        <Button
                        content= "Apply Again"
                        clickFunc= {() => goToLoan()} />
                    </div>
                )}
            </div> : null}

            {/* 评分弹框 */}
            {isScore ? (
                <div className="P-loanDetails_score">
                    <div className="score_mask"></div>
                    <div className="score_info">
                        <img src={closeIcon} className="closeIcon" onClick={() => closeScoreModal()} />
                        <div className="info">
                            <p className="info_desc">Rate Us</p>
                            <p className="info_desc tips">Please take a few seconds to rate our app. Your feedback will help us improve!</p>
                            <div className="star">
                                {scoreList.map((item, index) => {
                                    return (
                                        <div className="starItem">
                                            <img src={clickIndex == item.index ? item.checked : item.not_checked} key={"scoreStar" + index} onClick={() => clickScore(item)} className={item.index == clickIndex ? "rotate_star" : ""} />
                                            <p style={{color: item.index == clickIndex ? "#007CFB" : "#DDE2E6"}}>{ item.index }</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="buttonArea">
                            <Button
                            content= "Rate Now"
                            clickFunc= {() => upScore()} />
                        </div>
                    </div>
                </div>
            ) : null}

            {/* 银行卡错误提示弹框 */}
            {isBoxBank ? (
                <ErrorModal
                isClose
                closeFunc={() => closeBankModal()}
                loanFailedType={loanFailedType}
                loanFailedTitle={loanFailedTitle}
                loanFailedTxt={loanFailedTxt}
                btnArray={[{
                    content: "Replace",
                    clickFunc: () => changeAccount(),
                    btnStyle: {}
                }]} />
            ) : null}

            {/* 审核中展示详情的弹框 */}
            {isShowDetails ? (
                <Modal
                titleContent="Details"
                close={() => SetIsShowDetails(false)}>
                    {detail.length > 0 ? (
                        <ul className="ing_details">
                            {detail.map((item, index) => {
                                return (
                                    <li key={"ing" + index}>
                                        <p>{item.installation}</p>
                                        <p className="value">{item.unless}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    ) : null}
                </Modal>
            ) : null}
        </div>
    )
}

export default LoanDetails;
import React, { useState } from 'react';
import Button from '../button';
import './index.less';
import closeIcon from "../../common/images/closeIcon.png";

const Modal = (props) => {
    const { titleContent, close, children } = props;
    return (
        <div className='M-modal'>
            <div className='M-modal_mask'></div>
            <div className='M-modal_content'>
                <div className='top'>
                    <p>{titleContent || ""}</p>
                    <img src={closeIcon} className='closeIcon' onClick={() => close()} />
                </div>
                <div className='content'>
                    <div className='scrollArea'>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;
import React from "react";
import './index.less';
import left_icon from '../../common/images/global/left_icon.png';
import right_icon from '../../common/images/global/right_icon.png';

const DetailsTop = (props) => {
    const { title, children, tipsContent, noTxt } = props;
    return (
        <div className="M-detailsTop">
            <p className="title">{ title }</p>
            <div className="center" style={{marginBottom: noTxt ? "0" : "60px"}}>
                <img src={left_icon} className="left_icon" />
                { children }
                <img src={right_icon} className="right_icon" />
            </div>
            {!noTxt ? <p className="tips">Dear valued customer,</p> : null}
            {!noTxt ? <p className="tips">{ tipsContent }</p> : null}
            {!noTxt ? <p className="tips">Thank you for your understanding and cooperation.</p> : null}
        </div>
    )
}

export default DetailsTop;
import React, { useState, useEffect, useRef } from "react";
import { setTitle } from "../../../common/js/tools";
import { setNavColor, setNavExpansion } from "../../../common/js/navigateTools";
import { delayDetailService } from '../../../axios/service';
import RepaymentOfCard from "../../../components/card/repaymentOfCard";
import Details from "../../../components/details";
import Button from "../../../components/button";
import "./repayment.less";

const RepaymentOfDelay = (props) => {

    const [productLogo, setProductLogo] = useState("");
    const [productName, setProductName] = useState("");
    const [delayShouldPayAmount, setDelayShouldPayAmount] = useState(""); // 还款金额
    const [orderDetail, setOrderDetail] = useState([]);
    const [amount, setAmount] = useState(""); // 借款金额

    const [isShowRepayType, setIsShowRepayType] = useState(false); // 是否展示还款方式弹框

    // const [autoAmount, setAutoAmount] = useState(null); // 上方显示的金额
    // const [autoAmountTxt, setAutoAmountTxt] = useState(""); // 上方显示的金额提示

    useEffect(() => {
        setTitle("Extension");
        setNavExpansion("0"); // 隐藏导航栏
        setNavColor("", "#F5F5F5");

        const getPeriod = async () => {
            let data = await delayDetailService();
            setProductLogo(data.occupying || "");
            setProductName(data.proportion || "");
            setDelayShouldPayAmount(data.germany || "");
            setOrderDetail(data.titles && data.titles.dressed || []);
            setAmount(data.grunts || "");

            // setAutoAmount(data. || 0);
            // setAutoAmountTxt(data. || "");
        }
        getPeriod();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])
    const cardRef = useRef();
    let repayConfirm = () => {
        // cardRef.current.repayCommit();
        setIsShowRepayType(true);
    }
    return (
        <div className="P-repayment">
            {/* 展期信息区域 */}
            <div className="P-repayment_delayInfo">
                {orderDetail && orderDetail.length > 0 && (
                    <Details
                    title="Extension Details"
                    details={orderDetail} />
                )}
            </div>
            {/* 跳转按钮 */}
            <div className="P-repayment_btnArea">
                <Button
                content="Repay"
                clickFunc={() => repayConfirm()} />
            </div>
            {/* 还款方式 */}
            {/* <div className="cardArea"> */}
            {isShowRepayType ? 
                <RepaymentOfCard 
                ref={cardRef}
                amount={delayShouldPayAmount}
                isDelay={1}
                closeFunc={() => setIsShowRepayType(false)} /> : null}
            {/* </div> */}
        </div>
    )
}

export default RepaymentOfDelay;
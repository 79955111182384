import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import qs from 'qs';
import { userCardListService, changeOrderAccountService } from "../../../axios/service";
import { setTitle } from "../../../common/js/tools";
import { setNavExpansion, closeSyn, setNavColor } from "../../../common/js/navigateTools";
import "./index.less";

const BindCardList = (props) => {
    // 路由params
    const location = useLocation();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `declamation=${params.declamation}`;

    const navigate = useNavigate();

    const [list, setList] = useState([]);
    const [choosedBank, setChoosedBank] = useState({});
    const [bankList, setBankList] = useState([]); // 银行卡列表
    const [ewalletList, setEwalletList] = useState([]); // 电子钱包列表
    const [choosedBanner, setChoosedBanner] = useState(1); // 选择card类型 bank e-wallet 点进来默认E-wallet: 1
    const [choosedCard, setChoosedCard] = useState([]); // 选中card类型的列表

    useEffect(() => {
        setTitle("");
        setNavExpansion("0"); // 隐藏导航栏
        setNavColor("", "#F5F5F5");
        getBaseInfo();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])
    const getBaseInfo = async () => {
        let data = await userCardListService();
        setList(data.reliance);
        /*let bankList = []; let ewalletList = [];
        this.state.list.map(item => {
            if(item.deferring == "Bank") {
                bankList = item.impressing;
            } else if(item.deferring == "E-wallet") {
                ewalletList = item.impressing;
            }
            item.impressing && item.impressing.map(item1 => {
                if(item1.hapless == 1) {
                    this.setState({
                        choosedBank: item1, // 当前选中的卡
                    })
                }
            })
        });
        this.setState({
            bankList: bankList,
            ewalletList: ewalletList
        });*/
    }
    // 跳转到bindCard添加绑卡页面
    const jumpBindCard = (type) => {
        let choosed_banner = type; // 选中的卡类型 1E-wallet 2Bank
        setChoosedBanner(choosed_banner);
        // navigate(`/stationThere?notable=${params.notable}&conceive=${params.conceive}&choosedCardType=${choosed_banner}&${paramsDetail}`);
        window.location.href = `/stationThere?notable=${params.notable}&conceive=${params.conceive}&choosedCardType=${choosed_banner}&${paramsDetail}`;
    }
    // 确认更换银行卡/e-wallet
    const confirmBtn = async (choosedItem) => {
        let data = await changeOrderAccountService({
            bindId: choosedItem.squatting || "",
        });
        getBaseInfo();
        // if (params.espionage == '1') {
        //     closeSyn(); // 关闭当前H5
        // } else {
        //     // window.history.back();
        //     // window.history.go(-1);
        //     // window.location.reload();//强行刷新(返回上一页刷新页面)
        //     navigate(-1);
        // }
        navigate(-1);
    }
    // 选择银行卡/ewallet
    const chooseMainBank = (item) => {
        // console.log(item);
        setChoosedBank(item);
        confirmBtn(item);
    }
    // 换bank和E-wallet banner以及其列表 (不需要切换的就不使用)
    const changeCardType = (item) => {
        // console.log(item);
        let choosed_banner = item.fitted;
        setChoosedBanner(choosed_banner);
        let choosed_card = item.fitted == 2 ? bankList : ewalletList;
        setChoosedCard(choosed_card);
    }

    return (
        <div className="P-bindCardList">
            {list.length > 0 && list.map((item, index) => {
                return (
                    <div className="list" key={"cardLi" + index}>
                        <div className="list_type">
                            <p>{item.pinafore || ""}</p>
                        </div>
                        <ul className="list_s">
                            {item.religion && item.religion.length > 0 && item.religion.map((child, i) => {
                                return (
                                    <li className="s_info" key={"cardLii" + i} onClick={() => chooseMainBank(child)}>
                                        <div className="s_info_txt" style={{border: child.unsentimental ? "4px solid #007CFB" : "4px solid #fff"}}>
                                            <div className="name">
                                                {child.favour ? <img src={child.favour} className="card_logo" /> : null}
                                                <p>{child.attempting || ""}</p>
                                            </div>
                                            <p className="num">{child.emitted || ""}</p> 
                                        </div>
                                        {child.unusual != 0 ? (
                                            <div className={child.unusual == 1 || child.unusual == 7 ? "limit_card" : child.unusual == 2 || child.unusual == 99 ? "error_card" : ""}>
                                                <p>{child.oldest || ""}</p>
                                            </div>
                                        ) : null}
                                    </li>
                                )
                            })}
                            <li className="s_add" onClick={() => jumpBindCard(item.fitted)}>
                                <p>Add a new {item.fitted == 1 ? "E-wallet" : "Bank Card"}</p>
                            </li>
                        </ul>
                    </div>
                )
            })}
        </div>
    )
}

export default BindCardList;
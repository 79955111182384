export default {
    loanDetailConfirm: "/cll/treasuredShall", // 确认用款页详情
    loanDetailPush: "/cll/extraNecessity", // 确认用款
    loanDetails: "/cll/greekMoney", // 订单详情页
    googleScore: "/cll/ambitiousThrough", // google评分
    userReloan: "/cll/reviveAppearance", // 原卡重试确认订单
    loanPlayDetail: "/cll/seemingPardon", // 获取还款计划信息
    getLoanPayment: "/cll/firstThrew", // 获取还款方式
    delayDetail: "/cll/goldenDiscipline", // 展期详情页
    cardInit: "/cll/piousjocoseStonedeaf", // 获取绑卡信息（第五项）
    bindCardNew: "/cll/strangersWhich", // 提交绑卡（第五项）
    userCardList: "/cll/momentsMeans", // 用户银行卡列表
    changeOrderAccount: "/cll/knockOrdinary", // 更换银行卡（会触发重新打款）
    contactKfInfo: "/cll/preachesBarton", // 获取客服页面数据
    generateVcode: "/cll/wwlambda", // 获取还款code码
    // prohibitedProduct: "/uiengllli", // 禁止产品详情 - 小黑屋
    planList: "/cll/captainFunctions", // 确认用款页假产品数据变更接口
}
import React, { useState, useEffect} from "react";
// import { useSearchParams, useLocation } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import qs from 'qs';
import { setTitle } from "../../common/js/tools";
import { ToastType } from "../../common/js/toastList";
import { loanDetailConfirmService, loanDetailPushService, planListService } from "../../axios/service";
import { uploadRiskLoan, setNavExpansion, setNavColor } from "../../common/js/navigateTools";
import Button from "../../components/button";
import ErrorModal from "../../components/errorModal";
import Modal from "../../components/modal";
import './confirmOfLoan.less';

import bankError_confirm_errorIcon from "../../common/images/bankError/bankError_confirm_errorIcon.png";
import confirm_goBank from "../../common/images/confirmOfLoan/confirm_goBank.png";
import confirm_agree from "../../common/images/confirmOfLoan/confirm_agree.png";
import confirm_notAgree from "../../common/images/confirmOfLoan/confirm_notAgree.png";
import left_icon from '../../common/images/global/left_icon.png';
import right_icon from '../../common/images/global/right_icon.png';
import top_icon from '../../common/images/global/top_icon.png';
import shelf_add from '../../common/images/confirmOfLoan/shelf_add.png';
import shelf_minuse from '../../common/images/confirmOfLoan/shelf_minuse.png';
import authority from '../../common/images/confirmOfLoan/authority.png';

const ConfirmOfLoan = (props) => {
    // 路由params
    const location = useLocation();
    // console.log(qs.parse(location.search.substring(1, location.search.length)));
    // const params = Qs();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `declamation=${params.declamation}`;

    // 跳转路由
    const navigate = useNavigate();

    // state数据
    const [amount, setAmount] = useState(""); // 传值时传的字段 不带金额符号的借款金额
    const [periods, setPeriods] = useState(""); // 期限 - 传值
    const [termUnit, setTermUnit] = useState(""); // // 期限类型 - 传值
    const [amountTxt, setAmountTxt] = useState(""); // 带金额符号的借款金额
    const [amount_show, setAmountShow] = useState(""); // 展示用金额 （有逗号没有金额符号）
    const [details, setDetails] = useState([]); // 借款数据
    const [repay_plans, setRepayPlans] = useState([]); // 还款计划
    const [note, setNote] = useState(""); // 首期还款即结清提示
    const [firstPayTime, setFirstPayTime] = useState(""); // 第一期还款时间
    const [firstPeriodDay, setFirstPeriodDay] = useState(""); // 第一期借款期限
    const [productLogo, setProductLogo] = useState(""); // 产品logo
    const [productName, setProductName] = useState(""); // 产品名字
    const [isChekedAgreement, setIsChekedAgreement] = useState(true); // 借款协议的选择状态
    const [cardType, setCardType] = useState(1); // 卡的类型 1.ewallet 2 bank 3 线下便利店
    const [bankCard, setBankCard] = useState(""); // 银行账户号
    const [bankName, setBankName] = useState(""); // 银行名称
    const [bankText, setBankText] = useState(""); // 银行卡文案
    const [actualAmount, setActualAmount] = useState(0); //到账金额
    const [orderNo, setOrderNo] = useState(""); // 订单号
    const [isAgreeShow, setIsAgreeShow] = useState(false); // 是否显示同意借款协议
    const [isArrowShow, setIsArrowShow] = useState(false); // 是否显示跳转绑卡列表页
    
    const [isBoxBank, setIsBoxBank] = useState(0); // 是否弹框 1弹 0不弹
    const [loanFailedTxt, setLoanFailedTxt] = useState(""); // 弹框里的文字
    const [loanFailedTitle, setLoanFailedTitle] = useState(""); // 弹框里的标题
    const [loanFailedType, setLoanFailedType] = useState(0); // 0 正常银行卡，1和7限额银行卡，2是错误账户银行卡 99未知错误银行卡

    // 假产品
    const [isDisplayShelf, setIsDisplayShelf] = useState(1); // 1假产品 0真产品
    const [shelf_amountText, setShelfAmountText] = useState("0"); // 假产品的金额
    const [shelf_amount, setShelfAmount] = useState(0); // 假产品确认用款用的金额 默认amount
    const [shelf_totalAmount, setShelfTotalAmount] = useState("0"); // 借款本金加利息
    const [shelf_repay_plans, setShelfRepayPlans] = useState([]); // 假产品的分期信息
    const [shelf_chosedPeriods, setShelfChosedPeriods] = useState(30); // 选择的天数（默认30） - 期数 * 30
    const shelf_term = [1, 3, 6, 9, 12]; // 期数（次数）数组
    const [shelf_chosedTerm, setShelfChosedTerm] = useState(1); // 选择的期数（默认1）
    const [shelf_firstPayTimeTips, setShelfFirstPayTimeTips] = useState(""); // 第一次还款的提示
    const [shelf_firstPayTime, setShelfFirstPayTime] = useState(""); // 第一次还款的时间
    const [isShowTermPicker, setIsShowTermPicker] = useState(false); // 是否弹出选择期数的弹框

    const [shelf_width, setShelfWidth] = useState(0); // 滑动图标滑动的位置
    const [shelf_minusRange, setShelfMinusRange] = useState(0); // 滑动时，顶部与X坐标之间的差距
    const [shelf_allWidth, setShelfAllWidth] = useState(0); // 滑动时总长度
    
    useEffect(() => {
        setTitle("");
        setNavExpansion("0"); // 隐藏头部

        // 获取确认用款详情
        const getLoanData = async () => {
            let data = await loanDetailConfirmService();
            setAmount(data.grunts || 0);
            setPeriods(data.epsilon);
            setTermUnit(data.sigmaf);
            setAmountTxt(data.sophocles || "");
            setAmountShow(data.pretence || "");
            setDetails(data.absorbingly || []);
            setRepayPlans(data.perceived || []);
            setNote(data.unfavourable || "");
            setFirstPayTime(data.myself);
            setFirstPeriodDay(data.vanity);
            setProductLogo(data.occupying || "");
            setProductName(data.proportion || "");
            setCardType(data.fitted);
            setBankCard(data.replenished || "");
            setBankName(data.attempting || "");
            setBankText(data.insight || "");
            setActualAmount(data.alpha || "");
            setOrderNo(data.unlimited || "");
            setIsAgreeShow(data.quote || 0);
            setIsArrowShow(data.theta || 0);

            setIsBoxBank(data.omicron || 0);
            setLoanFailedTxt(data.oldest || "");
            setLoanFailedTitle(data.gamma || "");
            setLoanFailedType(data.unusual || "");

            let curr_isDisplayShelf = data.reception;
            setIsDisplayShelf(data.reception); // 1假产品0真产品
            if(curr_isDisplayShelf == 1) {
                setNavColor('', '#000');
                // 假产品
                getPlanList({
                    amount: data.grunts || 0,
                    periods: shelf_chosedPeriods,
                    termUnit: shelf_chosedTerm,
                });
            } else {
                setNavColor('', '#fff');
                setTitle('Confirm Loan Info');
            }
        }
        getLoanData();
        
        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])

    // 改变借款协议选中状态
    const changeAgreementCheckStatus = () => {
        setIsChekedAgreement(!isChekedAgreement);
    }
    // 跳转合同详情
    const goAgreement = () => {
        // window.location.href = `/wrongIdeally`;
        // props.history.push("/wrongIdeally");
        navigate("/wrongIdeally");
    }
    // 跳转换绑卡列表
    const changeAccount = () => {
        // props.history.push(`/mirrorWould?notable=${orderNo}&conceive=${params.conceive}&${paramsDetail}`);
        // navigate(`/mirrorWould?notable=${orderNo}&conceive=${params.conceive}&${paramsDetail}`);
        window.location.href = `/mirrorWould?notable=${orderNo}&conceive=${params.conceive}&${paramsDetail}`;
    }
    // 关闭状态弹框
    const closeErrorModal = () => {
        setIsBoxBank(0);
    }
    // 去借款
    const goLoan = async () => {
        if (!isChekedAgreement) {
            ToastType("Toast", "Please check the note first");
            return;
        }
        let startTimeD = new Date();
        let startTime = `${parseInt(startTimeD.getTime() / 1000)}`;
        let service_data = {};
        if(isDisplayShelf) {
            // 假产品
            service_data = {
                amount: shelf_amount, // 借款金额
                periods: shelf_chosedPeriods, // 期限
                termUnit: shelf_chosedTerm, // 期限类型
            }
        } else {
            service_data = {
                amount: amount, // 借款金额
                periods: periods, // 期限
                termUnit: termUnit, // 期限类型
            };
        }
        let data = await loanDetailPushService(service_data);
        uploadRiskLoan(params.conceive, startTime);
        data.ulysses && window.location.replace(`${data.ulysses}&${paramsDetail}`); // 跳转到订单详情页
        // data.ulysses && props.history.replace(`${data.ulysses}&${paramsDetail}`); // 跳转到订单详情页
        // data.ulysses && navigate(`${data.ulysses}&${paramsDetail}`, {replace: true}); // 跳转到订单详情页
    }

    /**
     * 假产品方法
     * @param {*} shelfData 
     */
    // 获取假产品信息
    const getPlanList = async (shelfData) => {
        let data = await planListService(shelfData);
        // console.log(data);
        setShelfAmountText(data.phipps || "0");
        setShelfAmount(data.grunts || 0);
        setShelfTotalAmount(data.debatable || "0");
        setShelfRepayPlans(data.perceived || []);
        setShelfFirstPayTimeTips(data.nsdared || "");
        setShelfFirstPayTime(data.downgola || "");
        // let curr_shelf_width = (data.grunts - 3000) / 1000 / 62 * 100; // 金额选择滑动区域的金额占比 用于滑动条的宽度显示
        // setShelfWidth(curr_shelf_width);
    }
    // 选择金额重新更新
    const choseAmount = (choseAmount) => {
        getPlanList({
            amount: choseAmount,
            periods: shelf_chosedPeriods,
            termUnit: shelf_chosedTerm,
        });
    }
    // 更换期限
    const changePeriods = (item) => {
        setShelfChosedPeriods(item);
        getPlanList({
            amount: shelf_amount,
            periods: item,
            termUnit: shelf_chosedTerm,
        });
    }
    // 更换期数
    const changeTerm = (item) => {
        setShelfChosedTerm(item);
        setShelfChosedPeriods(Number(item * 30));
        getPlanList({
            amount: shelf_amount,
            periods: Number(item * 30),
            termUnit: item,
        });
        setIsShowTermPicker(false);
    }

    // 触摸开始
    const ononTouchStartFunc = (e) => {
        // console.log(e.touches[0]);
        let rangeArea = document.getElementById("rangeArea");
        // let scrollRangeArea = document.getElementById("scroll_rangeArea");
        // let curr_shelf_minusRange = e.touches[0].clientX - scrollRangeArea.offsetWidth;
        let curr_shelf_minusRange = rangeArea.offsetLeft; // e.touches[0].clientX - scrollRangeArea.offsetWidth
        // console.log(rangeArea.offsetWidth, rangeArea.offsetLeft, curr_shelf_minusRange); // , scrollRangeArea.offsetWidth
        setShelfAllWidth(rangeArea.offsetWidth);
        setShelfMinusRange(curr_shelf_minusRange);
    }
    // 触摸滑动
    const onTouchMoveFunc = (e) => {
        // console.log(e.touches[0].clientX, shelf_minusRange, shelf_allWidth);
        let width = e.touches[0].clientX - shelf_minusRange;
        moveFunc(width);
        if(shelf_amount <= 3000) {
            setShelfWidth(0);
            setShelfAmount(3000);
            if(width > 0) {
                // console.log(111);
                moveFunc(width);
            }
        }
        if(shelf_amount >= 65000) {
            setShelfWidth(100);
            setShelfAmount(65000);
            if(width < shelf_allWidth) {
                // console.log(222);
                moveFunc(width);
            }
        }
    }
    // 滑动方法
    const moveFunc = (width) => {
        let width_rate = width / shelf_allWidth;
        let one_rate = shelf_allWidth / 62; // 总长度一共被分为62份（已千为隔断, 初识为3k），one_rate一份占的比例
        let all_rate = parseInt(width / one_rate); // 滑动的长度占了多少比例，向下取整
        // console.log(width, width_rate);
        let curr_shelf_amount = all_rate * 1000 + 3000; // 滑动的得到的金额
        // console.log(all_rate);
        setShelfWidth(width_rate * 100);
        setShelfAmount(curr_shelf_amount);
    }
    // 滑动结束请求接口
    const onTouchEndFunc = () => {
        getPlanList({
            amount: shelf_amount,
            periods: shelf_chosedPeriods, // 期限
            termUnit: shelf_chosedTerm, // 期数
        });
    }

    // 更换金额
    const changeAmount = (type) => {
        getPlanList({
            amount: type == 0 ? shelf_amount - 1000 : type == 1 ? shelf_amount + 2000 : shelf_amount,
            periods: shelf_chosedPeriods,
            termUnit: shelf_chosedTerm,
        });
    }
    // 点开选择期数的弹框
    const showTermPicker = () => {
        setIsShowTermPicker(true);
    }

    return (
        <div className="P-confirmOfLoan">
            {!isDisplayShelf ? (
                <div className="P-confirmOfLoan_realArea">
                    <p className="realArea_tips">Making timely repayments and maintaining good credit can both increase your credit limit!</p>
                    {/* 顶部借款金额 */}
                    <div className="P-confirmOfLoan_amount">
                        <p className="title">Loan Amount</p>
                        <p className="amount"><span>₱</span>{amount_show || "0"}</p>
                    </div>
                    {/* 借款信息区域 */}
                    <div className="P-confirmOfLoan_infoArea">
                        {/* 银行卡信息区域 */}
                        <div className="infoArea_bank">
                            <p className="name">{bankName || "Name of Bank"}</p>
                            <div className="bank_info">
                                {loanFailedType != 0 ? <img src={bankError_confirm_errorIcon} className="error_tipsIcon" /> : null}
                                <div className="card"> 
                                    <p className="card_num">{bankCard || "-"}</p>
                                    {isArrowShow ? <img src={confirm_goBank} onClick={() => changeAccount()} className="confirm_goBank" /> : null}
                                </div>
                            </div>
                            {loanFailedType != 0 ? (
                                <div className="bank_error">
                                    <p className="tips">{loanFailedTxt || "Your account has been limited. Please change your account!"}</p>
                                </div>
                            ) : null}
                        </div>
                        {/* details数据 */}
                        {details && details.length > 0 && (
                            <ul className="infoArea_details ellipsis">
                                <p className="title">Confirm Loan Info</p>
                                {details.map((item, index) => {
                                    return (
                                        <li key={"conf_detailsI" + index}>
                                            <p className="details_left">{item.installation || ""}</p>
                                            <p className="details_right">{item.unless || ""}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        )}
                        {/* 还款计划信息区域 */}
                        {repay_plans && repay_plans.length > 0 && (
                        <div className="infoArea_repay">
                            <p className="title">Confirm Repayment Info</p>
                            <ul className="repay_info">
                                {repay_plans.map((item, index) => {
                                    return (
                                        <li key={"conf_repayI" + index} style={{marginBottom: note.length <= 0 ? '20px' : ''}}>
                                            <div className="index">
                                                <p className="index_num">{item.affections.substring(0, 1) || "-" }</p>
                                                <p className="index_th">{item.affections.substring(1, item.affections.length) || ""}</p>
                                            </div>
                                            <div className="info ellipsis">
                                                <div className="info_amount">
                                                    <p>{item.director || ""}</p>
                                                    <p className="introduce">Loan date</p>
                                                </div>
                                                <div className="info_date">
                                                    <p>{item.grunts || ""}</p>
                                                    <p className="introduce">{item.interfering || ""}</p>
                                                </div>
                                            </div>
                                            {/* 还款提示 */}
                                            {repay_plans && repay_plans.length > 0 && index == 0 && note.length > 0 ? 
                                            <div className="infoArea_tips">
                                                <p className="tips">{note || ""}</p>
                                            </div> : null}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>)}
                    </div>
                </div>
            ): (
                <div className="P-confirmOfLoan_shelfArea">
                    {/* 假产品选择内容区域 */}
                    <div className="shelfArea_choseArea">
                        {/* 滑动金额区域 */}
                        {/* <div className="shelfArea_choseArea_scrollAmount">
                            <div className="scrollAmount">
                                <p>Min</p>
                                <div className="scrollAmount_scroll" id="rangeArea">
                                    <img 
                                    src={shelf_chosedIcon} 
                                    className="shelf_chosedIcon"
                                    style={{left: `${shelf_width}%`}}
                                    onTouchStart={(e) => ononTouchStartFunc(e)}
                                    onTouchMove={(e) => onTouchMoveFunc(e)}
                                    onTouchEnd={() => onTouchEndFunc()} />
                                </div>
                                <p>Max</p>
                            </div>
                            <p className="scrollAmount_tips">You can freely choose a loan amount between 3,000 and 65,000, or select a preset amount.</p>
                        </div> */}
                        <img src={top_icon} className="top_icon" />
                        {/* 金额更换区域 */}
                        <div className="shelfArea_choseArea_changeAmount">
                            <p className="title">Confirm Loan Info</p>
                            <div className="amount_area">
                                <img src={left_icon} className="left_icon" />
                                <div className="amount">
                                    <p className="amount_tips">Daily rate (0.06%)</p>
                                    <p>{ shelf_amountText || "0" }</p>
                                </div>
                                <img src={right_icon} className="right_icon" />
                            </div>
                            <p className="tips">Adjustment range: 2000 to 50000</p>
                            <div className="change_area">
                                <img src={shelf_minuse} className="shelf_minuse" onClick={() => changeAmount(0)} />
                                <img src={shelf_add} className="shelf_add" onClick={() => changeAmount(1)} />
                            </div>
                        </div>
                        {/* 期数选择和展示区域 */}
                        <div className="shelfArea_choseArea_changeTerm">
                            <p className="title title_left">Repayment Info</p>
                            <div className="change_term" onClick={() => showTermPicker()}>
                                <p>{ shelf_chosedTerm } Month</p>
                                <img src={confirm_goBank} className="arrow_icon" />
                            </div>
                            <div className="date">
                                <p className="date_tips">{ shelf_firstPayTimeTips || "-" }</p>
                                <p className="date_time">{ shelf_firstPayTime || "-" }</p>
                            </div>
                            <div className="amount">
                                <p>{ shelf_repay_plans[0] && shelf_repay_plans[0].grunts || "₱0" }</p>
                                <p className="amount_tips">1st of { shelf_chosedTerm || "-"} installments</p>
                            </div>
                        </div>
                        {/* 银行卡信息区域 */}
                        <div className="shelfArea_choseArea_bank">
                            <p className="title title_left">Receiving Account</p>
                            <div className="card">
                                <div className="card_num">
                                    <p>{bankCard || "-"}</p>
                                    {isArrowShow ? <img src={confirm_goBank} onClick={() => changeAccount()} className="confirm_goBank" /> : null}
                                </div>
                                <p className="card_name">{ bankName || "-" }</p>
                            </div>
                        </div>
                        {/* 监管机构 */}
                        <div className="shelfArea_choseArea_authority">
                            <p className="title title_left">Regulatory Authority</p>
                            <img src={authority} className="authority" />
                        </div>
                    </div>
                </div>
            )}
            {/* 固定底部区域 */}
            <div className="P-confirmOfLoan_bottom" style={{background: isDisplayShelf ? '#2C2C36' : '#fff'}}>
                {/* 借款协议 */}
                {isAgreeShow ? 
                <div className="bottom_agreement">
                    <img src={isChekedAgreement ? confirm_agree : confirm_notAgree} className="agreement_checkedStatus" onClick={() => changeAgreementCheckStatus()} />
                    <p>I have read and agree to this <span className="loanAgreement" style={{color: isDisplayShelf ? '#fff' : '#007CFB'}} onClick={() => goAgreement()}>Loan Agreement</span>.</p>
                </div> : null}
                {/* 按钮 */}
                <div className="bottom_btnArea">
                    <Button
                    content="Apply Now"
                    clickFunc={() => goLoan()} />
                </div>
            </div>

            {/* 银行卡错误提示弹框 */}
            {isBoxBank ? (
                <ErrorModal
                loanFailedType={loanFailedType}
                loanFailedTitle={loanFailedTitle || ""}
                loanFailedTxt={loanFailedTxt || ""}
                btnArray={[{
                    content: "Continue",
                    clickFunc: () => closeErrorModal(),
                    btnStyle: {background: '#F2F3F5', color: '#B8C0C9'}
                }, {
                    content: "Replace",
                    clickFunc: () => changeAccount(),
                    btnStyle: {}
                }]}>
                    <div className="p-confirmOfLoan_errorBank">
                        <div className="errorBank_info">
                            <p className="bankName">{bankName || "Receiving Account"}</p>
                            <p className="bankNum" style={{color: loanFailedType == 0 ? '#2C2C36' : '#FC4C4D'}}>{bankCard || "-"}</p>
                        </div>
                        {loanFailedType != 0 ? <img src={bankError_confirm_errorIcon} className="errorBank_icon" /> : null}
                    </div>
                </ErrorModal>
            ) : null}

            {/* 选择期数的选择框 */}
            {isShowTermPicker ? (
                <Modal
                titleContent=""
                close={() => setIsShowTermPicker(false)}>
                    <ul className="term_picker">
                        {shelf_term.map((item, index) => {
                            return (
                                <li key={"confirmTerm" + index} onClick={() => changeTerm(item)}>
                                    <p>{ item } Month</p>
                                </li>
                            )
                        })}
                    </ul>
                </Modal>
            ) : null}
        </div>
    )
}

export default ConfirmOfLoan;


import React, { useState, useEffect, useRef } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import qs from 'qs';
import { setTitle } from "../../../common/js/tools";
import { setNavColor, setNavExpansion } from "../../../common/js/navigateTools";
import { loanPlayDetailService } from "../../../axios/service";
import RepaymentOfCard from "../../../components/card/repaymentOfCard";
import Button from "../../../components/button";
import "./repayment.less";

const RepaymentOfPeriod = (props) => {
    // 路由params
    // const location = useLocation();
    // const params = qs.parse(location.search.substring(1, location.search.length));
    // const paramsDetail = `declamation=${params.declamation}`;
    // 跳转路由 
    // const navigate = useNavigate();

    const [productName, setProductName] = useState("");
    const [repayPlanDetail, setRepayPlanDetail] = useState({}); // 内涵details
    const [totalAmount, setTotalAmount] = useState(""); // 总金额
    const [amount, setAmount] = useState(null); // 带货币符号的总金额
    const [note, setNote] = useState("");
    const [isShowRepayType, setIsShowRepayType] = useState(false); // 是否展示还款方式弹框

    // const [autoAmount, setAutoAmount] = useState(null); // 上方显示的金额
    // const [autoAmountTxt, setAutoAmountTxt] = useState(""); // 上方显示的金额提示

    useEffect(() => {
        setTitle("Repayment Details");
        setNavExpansion("0"); // 隐藏导航栏
        setNavColor("", "#F5F5F5");
        
        const getPeriod = async () => {
            let data = await loanPlayDetailService();
            setProductName(data.proportion || "");
            setRepayPlanDetail(data.migratory || {});
            setTotalAmount(data.debatable || "");
            setAmount(data.grunts || "");
            setNote(data.unfavourable || "");

            // setAutoAmount(data.agreementPlace || 0);
            // setAutoAmountTxt(data.specificDeadline || "");
        }
        getPeriod();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])
    
    const cardRef = useRef();
    let repayConfirm = () => {
        // cardRef.current.repayCommit();
        setIsShowRepayType(true);
    }
    return (
        <div className="P-repayment">
            {/* 还款计划信息区域 */}
            <div className="P-repayment_planInfo">
                {repayPlanDetail.consented && repayPlanDetail.consented.length > 0 && (
                <ul className="repay_info">
                    {repayPlanDetail.consented.map((item, index) => {
                        return (
                            <li key={"conf_repayI" + index} style={{marginBottom: note.length <= 0 ? '20px' : ''}}>
                                <div className="infoArea">
                                    <div className="index">
                                        <p className="index_num">{item.enemy.substring(0, 1) || "-" }</p>
                                        <p className="index_th">{item.enemy.substring(1, item.enemy.length) || ""}</p>
                                    </div>
                                    <div className="info ellipsis">
                                        <div className="info_amount">
                                            <p>{item.caprices || ""}</p>
                                            <p className="introduce">Loan date</p>
                                        </div>
                                        <div className="info_date">
                                            <p>{item.submitted || ""}</p>
                                            <p className="introduce">{item.nuptials || ""}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* 还款提示 */}
                                {index == 0 && note.length > 0 ? 
                                    <p className="tips">{note || ""}</p> : null}
                            </li>
                        )
                    })}
                </ul>
                )}
            </div>
            {/* 跳转按钮 */}
            <div className="P-repayment_btnArea">
                <Button
                content="Confirm"
                clickFunc={() => repayConfirm()} />
            </div>
            {/* 还款方式 */}
            {/* <div className="cardArea"> */}
                {isShowRepayType ? 
                <RepaymentOfCard 
                ref={cardRef}
                amount={repayPlanDetail && repayPlanDetail.consented && repayPlanDetail.consented[0].debatable || ""}
                isDelay={0}
                closeFunc={() => setIsShowRepayType(false)} /> : null}
            {/* </div> */}
        </div>
    )
}

export default RepaymentOfPeriod;
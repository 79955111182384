import React, { useEffect } from 'react';
import { setTitle } from '../../common/js/tools';
import { setNavExpansion } from '../../common/js/navigateTools';
import './agreement.less';

const LoanAgreement = (props) => {
    useEffect(() => {
        setTitle('Loan Agreement');
        setNavExpansion("0");
    }, [])
    return (
        <div className="P-agreement">
            <p className="title">Omdub Loan Agreement</p>
            <p>Before you begin using our loan services, please carefully review the following terms. These terms are designed to ensure you have the best experience when applying for and using our services, while also protecting your rights and privacy.</p>
            <div>
                <p className='subtitle'>Service Description</p>
                <p>Omdub Loan App focuses on providing flexible loan services, including but not limited to:</p>
                <p>- Loan application: Easily apply for loans through our app. We provide an intelligent application system to ensure a simple and efficient loan application process.</p>
            </div>
            <div>
                <p className='subtitle'>Loan Eligibility</p>
                <p>To ensure a good user experience, we consider multiple factors, including:</p>
                <p>- Credit history: We review your credit history to determine appropriate loan terms.</p>
                <p>- Income level: Your income is crucial to ensuring you have sufficient repayment capability.</p>
                <p>- Other eligibility factors: We comprehensively consider other factors to ensure loan disbursement complies with regulations and our policies.</p>
            </div>
            <div>
                <p className='subtitle'>User Responsibilities</p>
                <p>As a user of Omdub Loan App, you have the following responsibilities:</p>
                <p>- Compliance: Ensure all activities on the app comply with regulations and adhere to our terms of use.</p>
                <p>- Account security: Protect your account information and do not share your login credentials with others.</p>
                <p>- Accurate information: Provide true and accurate personal information during loan application and use.</p>
                <p>- Responsible use of services: Avoid misuse, infringement, or destructive behavior when using our services.</p>
            </div>
            <div>
                <p className='subtitle'>Loan Application Process</p>
                <p>On Omdub Loan App, we have designed a simple and efficient loan application process:</p>
                <p>1. Register an account: Create an account, provide necessary personal information, and set up secure login credentials.</p>
                <p>2. Complete personal profile: Fill out your personal profile within your account, providing accurate contact details and identification documents.</p>
            </div>
            <div>
                <p className='subtitle'>Loan Amounts, Interest Rates, and Terms</p>
                <p>On Omdub Loan App, you can choose loan amounts based on personal needs and credit conditions:</p>
                <p>- Loan amounts: Up to 65,000 pesos.</p>
                <p>- Loan terms: 91 to 180 days.</p>
                <p>- Loan interest rates: Typically between 20% and 30%, specific rates vary based on credit conditions, loan amounts, and terms.</p>
            </div>
            <div>
                <p className='subtitle'>Service Changes and Termination</p>
                <p>We reserve the right to modify or terminate services based on business development and management needs:</p>
                <p>- Service changes: Services may be adjusted or improved based on market demand, regulatory changes, or business development, with advance notice to users.</p>
                <p>- Service termination: In extreme cases, partial or complete services may be terminated or suspended, with timely notification to users.</p>
                <p>- Notification methods: Notifications of service changes and terminations will be communicated through the app, email, or other effective means.</p>
                <p>- User rights: Before service termination, we will strive to fulfill existing contractual obligations and protect user rights.</p>
            </div>
            <div>
                <p className='subtitle'>Applicable Law and Dispute Resolution</p>
                <p>We follow these principles for applicable law and dispute resolution:</p>
                <p>- Applicable law: These terms are governed by relevant laws and regulations.</p>
                <p>- Dispute resolution: Parties should attempt to resolve disputes amicably through negotiation. If negotiations fail, disputes shall be submitted to the competent court.</p>
                <p>- Arbitration option: Parties may choose arbitration for dispute resolution, with the arbitration decision being final and binding.</p>
            </div>
            <div>
                <p className='subtitle'>Contact Information</p>
                <p>- Email: <span className='email'>omdublending.sc@omdublending.com</span></p>
            </div>
        </div>
    );
}

export default LoanAgreement;


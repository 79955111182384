import React, { useEffect } from 'react';
import { setTitle } from '../../common/js/tools';
import { setNavExpansion ,jumpToHome, setNavColor } from '../../common/js/navigateTools';
// import { prohibitedProductService } from '../../axios/service';
import DetailsTop from '../../components/detailsTop';
import Button from '../../components/button';
import './jointLogonFail.less';
import fail_icon from '../../common/images/status/fail_icon.png';
const JointLogonFail = (props) => {
    useEffect(() => {
        setTitle("");
        setNavExpansion("0"); // 隐藏导航栏
        setNavColor('', '#000');
        // this.getProhibitedProduct();

        window.addEventListener("pageshow", function(event) {
            if(event.persisted) {
              window.location.reload();
            }
        });
    }, [])
    // 获取小黑屋展示信息
    /*async getProhibitedProduct = () => {
        let data = await prohibitedProductService();
        console.log(data);
    }*/
    // 去首页
    const goIndex = () => {
        jumpToHome();
    }
    return (
        <div className="jointLogonFail-wrap">
            <div className='jointLogonFail-wrap_content'>
            <DetailsTop 
            title="Not allowed"
            tipsContent="Sorry, your qualifications do not meet the requirements for applying for this product. Please try again later or consider applying for other products.">
                <div className='fail_icon'>
                    <img src={fail_icon} />
                </div>
            </DetailsTop>
                <p className='content_tips'>You can attempt to borrow again after</p>
                <p className='tips_bold'>One Month</p>
            </div>
            <div className='jointLogonFail-wrap_btnArea'>
                {/* <p className='tips_homepage' onClick={this.goIndex.bind(this)}>Homepage</p> */}
                <Button content="Homepage" clickFunc={() => goIndex()} />
            </div>
        </div>
    );
}

export default JointLogonFail;


import React, { useEffect } from 'react';
import { setTitle } from '../../common/js/tools';
import { setNavExpansion } from '../../common/js/navigateTools';
import './agreement.less';

const PrivacyAgreement = (props) => {
    useEffect(() => {
        // 隐私协议
        setTitle('Privacy Agreement');
        setNavExpansion("0");
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="P-agreement">
            <p className="title">Omdub Loan Privacy Policy</p>
            <p>This privacy statement, in accordance with the Personal Data Protection Act (the "Act") and related regulations and guidelines, informs you of our terms and conditions regarding the collection, use, and protection of your personal data.</p>
            <div>
                <p className='subtitle'>1. Loan Provider Information</p>
                <p>- Company Name: OM-DUB LENDING CORP</p>
                <p>- SEC Number: CS201714096</p>
                <p>- C.A. Number: 2242</p>
                <p>- Website: https://omdublend.com/</p>
            </div>
            <div>
                <p className='subtitle'>2. Collection of Personal Data</p>
                <p>Personal data refers to any information that directly or indirectly identifies an individual. We collect personal details solely to verify your identity and provide the services you request. Without prior consent, we do not collect any personal information. For authentication and account management, we may request the following information:</p>
                <p>1. Identification Details: Full name, date of birth, place of birth, nationality, age, gender.</p>
                <p>2. Contact Information: Address (including city and postal code), phone number, email address.</p>
                <p>3. Financial Information: Bank account details (if necessary for verification), monthly income range.</p>
                <p>4. Professional Background: Educational background (highest degree attained), current employment status (employer's name and job title).</p>
            </div>
            <div>
                <p className='subtitle'>3. Information Collected When Using the Application</p>
                <p>1. Facial Recognition: As part of the authentication process, we will ask you to take a personal selfie. We will compare this with your identification photo to ensure that you have initiated the application and to prevent impersonation. Our data is encrypted, stored securely, and not shared with third parties. Personal information is deleted when you terminate our business relationship (cancel your account).</p>
                <p>2. Camera Permissions: Users need to use the camera to upload identification photos or other images to complete registration and verification.</p>
                <p>3. Album Permissions: To facilitate image uploads for verification and enhance the service experience, we request access to your photo album.</p>
                <p>4. IDFA Permissions: IDFA is used solely for user identification and is not linked to personal information such as names.</p>
                <p>5. Location Permissions: We use location information to confirm user identity and monitor account activities to reduce risks.</p>
            </div>
            <div>
                <p className='subtitle'>4. Information Sharing</p>
                <p>Personal details remain confidential unless explicitly stated in this policy or agreed upon by the user.</p>
            </div>
            <div>
                <p className='subtitle'>5. Consent for Data Collection</p>
                <p>Users consent to the analysis of their contact information and device usage data to ensure identity verification. This includes verifying activity numbers, call logs, and recent changes. Consent covers the direct collection of this information under relevant data protection laws or from partners engaged in identity verification.</p>
            </div>
            <div>
                <p className='subtitle'>6. Cookies and Tracking Technologies</p>
                <p>1. Cookies: We may use cookies and similar tracking technologies to enhance user experience and for analytics. Users can control cookie settings through their browser and opt out of certain cookies via our platform settings.</p>
                <p>2. Tracking Technologies: We use pixels, web beacons, and other tracking technologies to analyze trends, manage the website, track user movements, and collect demographic information. Reports may be based on individual and aggregate usage of these technologies.</p>
            </div>
            <div>
                <p className='subtitle'>7. Third-Party Links</p>
                <p>1. Third-Party Links: Our platform may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites. Users should read the privacy policies of these third parties.</p>
            </div>
            <div>
                <p className='subtitle'>8. Data Security</p>
                <p>1. Data Security: We implement appropriate technical and organizational measures to protect personal data from unauthorized access, disclosure, alteration, or destruction. However, no method of Internet transmission or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
            </div>
            <div>
                <p className='subtitle'>9. Children's Privacy</p>
                <p>1. Children's Privacy: Our services are not intended for children under 18. We do not knowingly collect personal data from children under 18. If we learn that we have collected such data, we will promptly delete it.</p>
            </div>
            <div>
                <p className='subtitle'>10. Changes to Privacy Policy</p>
                <p>1. Changes to Privacy Policy: We reserve the right to update or modify this privacy policy at any time. We will notify users of any changes by posting the updated policy on our website. Users are advised to regularly review this policy to stay informed of any changes.</p>
            </div>
            <div>
                <p className='subtitle'>11. Contact Us</p>
                <p>- Email: <span className='email'>omdublending.sc@omdublending.com</span></p>
            </div>
        </div>
    );
}

export default PrivacyAgreement;


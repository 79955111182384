// 设置标题和颜色
import { Qs } from "./tools";
import { ToastType } from "./toastList";
const params = Qs();
var u = navigator.userAgent, isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
// 跳转邮箱 带邮箱标题和订单id
export const jumpToEmailNative = (email, title, order_id) => {
    try {
        if(isiOS) {
            params.test == 1 && ToastType('Toast', `挑战带标题和订单号的Email${email} ${title} ${order_id} success`);
            window.webkit.messageHandlers.alligator.postMessage([email, title, order_id]);
        } else {
            // propesoPH.alligator(email, title, order_id);
        }
    } catch (err) {
        params.test == 1 && ToastType('fail', 'the function is not found!');
    }
}
// 是否隐藏头部导航栏 isScree1是0否 如果参数是空走默认
export const setNavExpansion = (isScree) => {
    try {
        if(isiOS) {
            params.test == 1 && ToastType('Toast', `是否隐藏头部导航栏${isScree} success`);
            window.webkit.messageHandlers.ugliQuinc.postMessage([isScree]);
        } else {
            // propesoPH.ugliQuinc(isScree);
        }
    } catch (err) {
        params.test == 1 && ToastType('fail', 'the function is not found!');
    }
}
// 头部导航栏颜色和字体 txtColor字体颜色  navColor导航栏颜色 如果参数是空走默认
export const setNavColor = (txtColor, navColor) => {
    try {
        if(isiOS) {
            params.test == 1 && ToastType('Toast', `头部导航栏颜色和字体${txtColor} ${navColor} success`);
            window.webkit.messageHandlers.liwuTange.postMessage([txtColor, navColor]);
        } else {
            // propesoPH.liwuTange(txtColor, navColor);
        }
    } catch (err) {
        params.test == 1 && ToastType('fail', 'the function is not found!');
    }
}
// 风控埋点
export const uploadRiskLoan = (productId, startTime) => {
    try {
        if(isiOS) {
            params.test == 1 && ToastType('Toast', `风控埋点 ${productId} ${startTime} success`);
            window.webkit.messageHandlers.bubbleVan.postMessage([productId, startTime]);
        } else {
            // propesoPH.bubbleVan(productId, startTime);
        }
    } catch (err) {
        params.test == 1 && ToastType('fail', 'the function is not found!');
    }
}
// 跳转原生或者H5
export const openUrl = (url) => {
    try {
        if(isiOS) {
            params.test == 1 && ToastType('Toast', `跳转原生或者H5 ${url} success`);
            window.webkit.messageHandlers.teapotUgl.postMessage([url]);
        } else {
            // propesoPH.teapotUgl(url);
        }
    } catch (err) {
        params.test == 1 && ToastType('fail', 'the function is not found!');
    }
}
// 关闭当前H5
export const closeSyn = () => {
    try {
        if(isiOS) {
            params.test == 1 && ToastType('Toast', '关闭当前H5 success');
            window.webkit.messageHandlers.rhinocero.postMessage("");
        } else {
            // propesoPH.rhinocero();
        }
    } catch (err) {
        params.test == 1 && ToastType('fail', 'the function is not found!');
    }
}
// 回到App首页
export const jumpToHome = () => {
    try {
        if(isiOS) {
            params.test == 1 && ToastType('Toast', '回到App首页 success');
            window.webkit.messageHandlers.snailYogu.postMessage("");
        } else {
            // propesoPH.snailYogu();
        }
    } catch (err) {
        params.test == 1 && ToastType('fail', 'the function is not found!');
    }
}
// 拨打电话
export const callPhoneMethod = (phoneNumber) => {
    try {
        if(isiOS) {
            params.test == 1 && ToastType('Toast', `拨打电话${phoneNumber} success`);
            window.webkit.messageHandlers.coconutOn.postMessage([phoneNumber]);
        } else {
            // propesoPH.coconutOn([phoneNumber]);
        }
    } catch (err) {
        params.test == 1 && ToastType('fail', 'the function is not found!');
    }
}
// 调用APP应用评分
export const toGrade = () => {
    try {
        if(isiOS) {
            params.test == 1 && ToastType('Toast', '调用APP应用评分 success');
            window.webkit.messageHandlers.quailBell.postMessage("");
        } else {
            // propesoPH.quailBell();
        }
    } catch (err) {
        params.test == 1 && ToastType('fail', 'the function is not found!');
    }
}
// 跳转Email
export const jumpToEmail = (email) => {
    params.test == 1 && ToastType('Toast', '跳转Email success');
    window.location.href = 'Mailto:' + email;
}
// 跳转whatsapp
export const jumpToWhatsapp = (phone) => {
    params.test == 1 && ToastType('Toast', `跳转whatsapp ${phone} success`);
    let newphone = phone;
    let newPhone;
    if (newphone.substr(0, 1) == 0) {
        newPhone = newphone.slice(1);
    } else {
        newPhone = newphone;
    }
    // console.log(newPhone);
    let url = `whatsapp://send?phone=${newPhone}&text=credit`;
    // console.log(url);
    window.location.href = url;
}
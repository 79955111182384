import React, { useState, useEffect } from 'react';
import './customerService.less';
import { setTitle } from "../../common/js/tools";
import { setNavExpansion, jumpToEmail, callPhoneMethod, jumpToWhatsapp, jumpToEmailNative, setNavColor } from '../../common/js/navigateTools';
import { contactKfInfoService } from "../../axios/service";
import jumpArrow from '../../common/images/custom/jumpArrow.png';

const CustomerService = (props) => {
    const [contactList, setContactList] = useState([]); // 展示list数据
    const [emailTitle, setEmailTitle] = useState(""); // 邮箱标题
    useEffect(() => {
        setTitle('Connect Us');
        setNavExpansion("0"); // 不隐藏头部导航栏
        setNavColor('', '#fff'); // 设置导航栏颜色

        // 获取客服信息
        const getContractList = async () => {
            let data = await contactKfInfoService();
            setContactList(data || []);
        }
        getContractList();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])
    const jumpDetailContact = (item) => {
        switch (item.excuse) {
            case "email":
                // jumpToEmail(item.unless);
                jumpToEmailNative(item.unless, "Contact Us", "");
                break;
            case "phone":
                callPhoneMethod(item.unless);
                break;
            case "whatsapp":
                jumpToWhatsapp(item.unless);
                break;
            case "feedback":
                jumpToEmailNative(item.unless, emailTitle, "");
                break;
        }

    }
    return (
        <div className="P-customerService">
            <div className='P-customerService_tips'>
                <p>When providing feedback, please include your account information, specific steps taken, and screenshots of the issue. This will help us better understand and resolve your problem.</p>
            </div>
            <div className='P-customerService_content'>
                {contactList && contactList.length > 0 && (
                <ul className='P-customerService_content_list'>
                    {contactList.map((item, index) => {
                        return (
                            <li className='P-customerService_content_list_li' key={"contactItem" + index} onClick={() => jumpDetailContact(item)}>
                                <div className='list_con_right'>
                                    {/* {item.identify ? (<img src={item.identify}  />) : null} */}
                                    <div className='list_con_p'>
                                        <p className='list_con_p_top'>{item.interfering || "none"}</p>
                                        <p>{item.installation || "none"}</p>
                                    </div>
                                </div>
                                <img src={jumpArrow} className='list_con_left' />
                            </li>
                        )
                    })}
                </ul>
                )}
            </div>
        </div>
    );
}

export default CustomerService;

